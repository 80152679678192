@import "../main/abstracts/index";
body[data-template="home"]{
    .section--event-list{
        position: relative;
        .wappen{
            position: absolute;
            right: 50px;
            top: 0px;
            transform: translateY(-25%);

        }
        .event-list__item{
            a{
                height: 100%;
                display: flex;
                flex-direction: column;
                font-weight: $font__weight--normal;
                @include hover-focus-visible-within{
                    .card-inner--wrapper .more-info{
                        background-color: $color__primary;
                        img{
                            @include recolor($color__white)
                        }
                    }
                    .event__image,
                    .event__image--fallback{
                        img{
                            transform: scale(1.1);
                        }
                    }
                }
                &:before{
                    display: none;
                }
                .event__image,
                .event__image--fallback{
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: auto;
                        @include transition;
                    }
                }
                .date-wimpel{
                    position: absolute;
                    left: 32px;
                    top: 32px;
                    width: 110px;
                    height: 110px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: $color__white;
                    z-index: 2;
                    border-radius: 55px;
                    line-height: 1;
                    font-weight: $font__weight--normal;
                    
                    .day{
                        color: $color__primary;
                        line-height: 1;
                        @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 24px, 40px);
                    }
                    .month{
                        line-height: 1;
                        font-size: 18px;
                    }

                }
                .card-date{
                    color: $color__primary;
                    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 16px, 18px);
                }
                .d-flex p,
                .address-individual{
                    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 16px, 18px);
                    flex: 1;
                }
                .card-text__info{
                    h3{
                        font-weight: $font__weight--normal;
                        @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 20px, 25px);
                    }
                }
                .card-inner--wrapper{
                    flex: 1;
                    z-index: 2;
                    margin-top: -70px;
                    margin-left: 35px;
                    margin-right: 35px;
                    background-color: $color__gray-custom;
                    border-radius: 8px;
                    overflow: hidden;
                    display: flex;
                    @media(max-width: 575px){
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                    .card-text__info{
                        flex: 1;
                        padding: 25px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        .d-flex{
                            flex: 1;
                            align-items: flex-start;
                            flex-direction: row;

                            .icon--fluid,
                            img{
                                width: 24px;
                                height: 24px;
                            }
                        }
                        
                    }
                    .more-info{
                        width: 60px;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        border-left: 1px solid rgba(#8F98AB,0.25);
                        img{
                            @include recolor($color__primary)
                        }
                    }
                }
            }
        }
        .event-list__footer{
            .splide__link{
                justify-content: flex-start;
                @media(max-width: 575px){
                    margin-top: 0;
                    margin-bottom: 25px;
                }
            }
            .splide__arrows{
                justify-content: flex-end;
                @include media-breakpoint-down(md) {
                    margin-top: 16px;
                    gap: 2px; 
                }
                .splide__arrow{
                    border-radius: 50%;
                    width: 58px;
                    height: 58px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        padding: 2px 0;
                    }
                    &[disabled]{
                        background-color: $color__white;
                        img{
                            @include recolor($color__primary)
                        }
                    }
                }
            }
        }
    }

}
